<template>
  <div class="px-4 py-2 confirmation">
    <!-- <div class="py-4 font-bold text-black border-b border-gray-300 title">
      确认订单
    </div> -->

    <div class=" border-b border-gray-300 content_item">
      <!-- <div class="flex items-center item">
        <div style="width:80px;">确认码：</div>
        <div style="width:250px" class="py-2 pr-4 input_box">
          <a-input size="small" style="width:250px" placeholder="请输入确认码" v-model="code" />
        </div>
        <a-button style="margin-left:60px" size="small" type="primary" @click="getCollectInfoFunc">
          确定
        </a-button>
      </div> -->


      <!-- <div class="w-full pt-4 item">
        <div class="py-2 font-bold item_title">采集信息</div>
        <div class="flex justify-between py-3 item_box w-6/7">
          <div>
            <span class="list_title">预约机型：</span>
            <span class="list_msg">001</span>
          </div>
          <div>
            <span class="list_title">类别：</span>
            <span class="list_msg">上衣</span>
          </div>

          <div>
            <span class="list_title">采集编号：</span>
            <span class="list_msg">B00101</span>
          </div>
          <div>
            <span class="list_title">规格：</span>
            <span class="list_msg">S码</span>
          </div>
        </div>
        <div>
          <span class="list_title">预约时间：</span>
          <span class="list_msg">2020年1月1日 15:30 ~ 16:30</span>
        </div>
      </div> -->
    </div>
    <!-- <div class="pb-6 border-b border-gray-300 content_item">
      <div class="w-full pt-4 item">
        <div class="py-2 font-bold item_title">支付信息</div>
        <div class="flex justify-between py-3 item_box w-6/7">
          <div>
            <span class="list_title">支付方式：</span>
            <span class="list_msg">微信支付</span>
          </div>
          <div>
            <span class="list_title">优惠信息：</span>
            <span class="list_msg">无</span>
          </div>

          <div>
            <span class="list_title">付款时间：</span>
            <span class="list_msg">2020年1月1日 15:30</span>
          </div>
          <div>
            <span class="list_title"></span>
            <span class="list_msg"></span>
          </div>
        </div>
        <div>
          <span class="list_title">订单编号：</span>
          <span class="list_msg">4864156408154812561361654879463</span>
        </div>
      </div>
    </div> -->
    <div class="content_item ">

<!-- add -->
<div class="items-center item">
        <div style="width:80px;float:left;line-height: 40px;">订单编号：</div>
        <div style="float:left;width:250px" class="py-2 pr-4 input_box">
          <a-input size="small" style="width:250px" placeholder="请输入确认码" v-model="ordersn" />
        </div>

        <div style="width:80px;float:left;margin-left:80px;line-height: 40px;">商户名称：</div>
        <div  style="float:left;width:250px" class="py-2 pr-4 input_box">
          <a-input size="small" style="width:250px" placeholder="请输入确认码" v-model="merchant_title" />
        </div>
        <div style="clear:both;width:80px;float:left;line-height: 60px;">采集时间：</div>
        <div style="float:left;" class="py-2 input_box">
            <div class="py-2 pr-4 input_box">
              <a-range-picker
                size="small"
                style="width: 250px"
                :placeholder="['开始日期', '结束日期']"
                :value="showDateTime"
                @change="onChange"
              >
                <template slot="dateRender" slot-scope="current">
                  <div
                    class="ant-calendar-date"
                    :style="getCurrentStyle(current)"
                  >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </div>
      </div>
      </div>

      

      <a-button style="float:left;margin:15px 0px 0px -100px;" size="small" type="primary" @click="shaixuan">
          筛选
        </a-button>
        <a-button style="float:left;margin:15px 0px 0px 10px;" size="small" type="primary" @click="chongzhi">
          重置
        </a-button>
<!--  add -->
      <div style="clear:both" class="w-full item">
        <div class="py-2 font-bold item_title">待确认</div>
        <div class="pt-4">
          <a-table
            :data-source="dataSource"
            :columns="columns"
            :scroll="{ x: 1000 }"
            :pagination="pagination"
          >
            <template slot="operation" slot-scope="text, record">
              <div class="flex">
                <a @click="comfirmOrder(record.key)">确认订单</a>
              </div>
            </template>
          </a-table>
        </div>
      </div>
      <!-- <div style="height:100px;width:100%"></div> -->
    </div>
    <a-modal
      v-model="show"
      title="确认订单"
      @ok="confirmOrderFunc"
      :width="740"
    >
      <div v-if="orderInfo.code" class="p-3">
        <!-- <div class="flex items-center py-2">
          <div style="width:100px">确认码：</div>
          <div class="py-2 pr-4 input_box">
            <a-input placeholder="请输入确认码" />
          </div>
        </div> -->

        <div class="pl-2 m-2 border-l-4 border-primary text-bold">
          商家名称
        </div>
        <div class="py-1 pl-2 m-2 font-bold">
          {{ orderInfo.member_name }}
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          确认码
        </div>
        <div class="py-1 pl-2 m-2 font-bold">
          {{ orderInfo.code }}
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          选择采样机型
        </div>
        <div class="py-1 pl-2 m-2 font-bold">
          {{ orderInfo.name }}
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          采样机器人名称
        </div>
        <div class="py-1 pl-2 m-2 font-bold">
          {{ orderInfo.type_name }}
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          采样标签
        </div>
        <div class="flex flex-wrap items-center py-1 pl-2 m-2 font-bold">
          <div v-for="item in orderInfo.tags" class="p-1" :key="item">
            <div class="box min_box text-gray">{{ item }}</div>
          </div>
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          采样规格
        </div>
        <div class="py-1 pl-2 m-2 font-bold">
          {{ orderInfo.size }}
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          预约时间
        </div>
        <div class="py-1 pl-2 m-2 font-bold">
          {{ orderInfo.date }} {{ orderInfo.start_time }}-{{
            orderInfo.end_time
          }}
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          数据范围
        </div>
        <div class="px-1 pl-4 my-2">
          <div class="flex items-center py-2 header">
            <div class="text-sm title text-gray">部位</div>
            <div class="text-sm section text-gray" style="flex:1">区间范围</div>
            <div class="text-sm min text-gray">最小值</div>
            <div class="text-sm max text-gray">最大值</div>
          </div>

          <div
            class="flex items-center py-2 size_item"
            v-for="(value, key) in orderInfo.model_data"
            :key="key"
          >
            <div class="text-base title">{{ dataMap[key] }}</div>
            <div
              class="flex items-center text-sm section text_box "
              style="flex:1"
            >
              <div class="box min_box text-gray">{{ value[0] }}</div>
            </div>
            <div class="flex text-sm text-right min text_box ">
              <div class="box min_box text-gray">{{ value[1] }}</div>
            </div>
            <div class="flex text-sm text-right max text_box ">
              <div class="box min_box text-gray">{{ value[2] }}</div>
            </div>
          </div>
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          商品名称
        </div>
        <div class="py-1 pl-2 m-2 font-bold">
          {{ orderInfo.good_name }}
        </div>
        <div class="pl-2 m-2 mt-4 border-l-4 border-primary text-bold">
          商品自编码
        </div>
        <div class="py-1 pl-2 m-2 font-bold">
          {{ orderInfo.type_code }}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { collectList, confirmOrder, getCollectInfo } from "@/api/collect";
import { transformDate } from "@/utils/transformDate";
export default {
  data() {
    return {
      showDateTime: null,
      list: [],
      show: false,
      code: "", // 输入的确认码
      ordersn:'',
      merchant_title:'',
      timearr:[],
      searchTime: [], 
      columns: [
        {
          title: "订单编号",
          dataIndex: "ordersn",
          // fixed: "left",
          width: 230,
        },
        { title: "商户名称", dataIndex: "member_name", width: 200 },
        {
          title: "采集时间",
          dataIndex: "date",
          width: 200,
        },
        {
          title: "采集机型",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "机器人名称",
          dataIndex: "type_name",
          width: 120,
        },
        {
          title: "编码",
          dataIndex: "code",
          width: 150,
        },
        {
          title: "规格",
          dataIndex: "size",
          width: 80,
        },
        {
          title: "操作",
          key: "operation",
          fixed: "right",
          width: 100,
          scopedSlots: { customRender: "operation" },
          // customRender: (text,record)=>{
          //     return "<div>13123</div>"
          //    // return text
          // },
        },
      ],
      dataMap: {
        length: "身高",
        shoulder: "肩宽",
        arm: "上臂围",
        bust: "胸围",
        waist: "腰围",
        hipline: "臀围",
        hipheight: "臀高",
        lower: "下身长",
        thigh: "大腿围",
        thigh_length: "大腿长",
        calf: "小腿围",
      },
      pagination: {
        // 关于页码的配置属性
        total: 0,
         pageSize: 7,
        pageNo: 1,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      dataSource: [],
      orderInfo: {},
    };
  },
  created() {
    this.aquireList();
  },
  methods: {
    // 请求数据
    comfirmOrder(id){
      this.code = this.dataSource[id].code
      this.getCollectInfoFunc() 
    },
    chongzhi(){
    this.ordersn ='',
        this.merchant_title ='',
       this.searchTime =[],
      this.aquireList();
    },
    shaixuan(){
      this.aquireList();
    },
    getCurrentStyle(current, today) {
      const style = {};
      if (current.date() === 1) {
        style.border = "1px solid #1890ff";
        style.borderRadius = "50%";
      }
      return style;
    },
    aquireList() {

      let temdata = {
        type: 7,
        page: this.pagination.pageNo,
        limit: this.pagination.pageSize,
        ordersn :this.ordersn,
        merchant_title :this.merchant_title,
        time:this.searchTime
      };
      console.log(temdata)
     
      collectList(temdata)
        .then((res) => {
          res.data.list.forEach((item, index) => {
            item.key = index;
          });
          this.pagination.total = res.data.count;
          this.dataSource = [];
          this.dataSource.push(...res.data.list);
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.aquireList();
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      this.pagination.pageNo = page;
      this.aquireList();
    },
    // 输入确认码触发的事件
    getCollectInfoFunc() {
      if (this.code == "") {
        this.$message.error("请输入确认码");
        return;
      }
      getCollectInfo({ code: this.code }).then((res) => {
        if (res.code == 200) {

          console.log(res.data.model_data)

          for (let key in res.data.model_data) {

            if(res.data.model_data[key] instanceof Array){

            }else{
              let arr = res.data.model_data[key]
              .replace("[", "")
              .replace("]", "")
              .split(",");
            let list = [];
            arr.forEach((item) => {
              if (item == "''") {
                list.push("");
              } else {
                item = item.replace(/\'/g, "");
                list.push(item);
              }
            });
            res.data.model_data[key] = list;
            }


 


          }
          console.log('dddddddddddddd')
          console.log(res.data.model_data)
          this.orderInfo = Object.assign({}, res.data);
          this.show = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    confirmOrderFunc() {
      confirmOrder({
        order_id: this.orderInfo.id,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("订单确认成功");
          this.show = false;
          this.code = "";
          this.aquireList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onChange(e) {
      this.showDateTime = e;
      let start = transformDate(e[0]);
      let end = transformDate(e[1]);
      this.searchTime = [];
      this.searchTime.push(start);
      this.searchTime.push(end);
      // this.aquireList();
    },
  },

};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.input_box {
  width: 432px;
}
.content_item {
  .item {
    .item_title {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .list_title {
      color: #000000;
    }
    .list_msg {
      color: #7c7c7c;
    }
    div {
      font-size: 14px;
      color: #000000;
    }
    .item_box {
      div {
        width: 25%;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
.confirmation {
  tr td:nth-child(1) {
    // color: #1890ff;
  }
}
tr:hover {
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.subscribe {
  width: 100vw;
  overflow: hidden;
}

.subscribe .cate_item:not(:last-child) {
  border-bottom: 4px solid #eef3f3;
  padding-bottom: 20px;
}

.subscribe .cate_item:not(:first-child) {
  padding-top: 20px;
}

.my_tabbar {
  @apply flex flex-row items-center justify-center p-2 bg-primary;
}

.circle {
  bottom: 0;
  left: 50%;
  height: 800px;
  width: 800px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 0.1;
  transform: translateX(-50%);
}

.item {
  image {
    width: 96px;
    height: auto;
  }

  .iconbox {
    .etcfont {
      font-size: 46px;
    }
  }
}

.list {
  background: #f7f7f7;
}

.size {
  view {
    background: #f7f7f7;
    width: 120px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.date_list {
  box-sizing: border-box;
  height: 60px;
  width: 15.5%;
  background: #f7f7f7;
  margin-top: 20px;
  border-radius: 10px;
  color: #807e81;
  padding: 6px 0px;
  box-sizing: border-box;
  min-width: 150px;
  view {
    font-size: 24px;
  }

  .info {
    color: #ff5c2b;
  }
}

.date_list_active {
  background-color: white;
  border: 2px solid #294DEA;
  color: #294DEA;

  view {
    color: #294DEA;
  }
}

.date_select {
  opacity: 0.5;
}

.scroll_box {
  //   height: 400px;
}

.title {
  font-size: 14px;
  width: 60px;
  text-align: left;
}

.section {
  width: 250px;
  text-align: right;
}

.section_box {
  width: 100px;
}

.min {
  width: 130px;
  text-align: right;
}

.min_box {
  width: 120px;
}

.max {
  width: 130px;
  text-align: right;
}

.max_box {
  width: 120px;
}

.box {
  background: #f7f7f7;
  height: 40px;
  line-height: 40px;
  padding: 0px 20px;
  border-radius: 10px;
}

.text_box {
  justify-content: flex-end;
}

.select_img {
  image {
    width: 20px;
    height: auto;
  }
}

.textarea_box {
  background-color: #f7f7f7;
}

.btnbox {
  height: 120px;
  box-shadow: 0px 1px 0px 0px #dddddd;
  background: white;
  width: 100vw;
}

.btn {
  width: 336px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 10px;
}

.left_btn {
  background-color: white;
  border: 2px solid #294DEA;
}

.right_btn {
  color: white;
  background-color: #294DEA;
}

.type_list {
  box-sizing: border-box;
  width: 152rpx;
  height: 40px;
}

.type_active {
  border: 2px solid #294DEA;
  color: #294DEA;
}

.millType {
  background-color: #294DEA;
  border-radius: 10px;

  view {
    color: #ffffff;
  }
}
</style>
